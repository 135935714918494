.filters-container {
  margin-bottom: 20px;
}

.filters {
  padding: 5px 15px 15px;
}

.filters-buttons {
  padding: 15px 0 0;
}

.filter-dropdown-wrapper {
  &:hover {
    cursor: pointer;
  }

  &.open {
    .filter-dropdown {
      color: $color-default-lighter;
    }

    .icon--down {
      display: none;
    }
    .icon--up {
      display: block;
    }
  }
}

.filter-dropdown {
  color: $color-primary;
  padding: 0 8px 5px;
}

.filter-dropdown__icon {
  position: absolute;
  top: 5px;
  right: 5px;

  &.icon--up {
    display: none;
  }
}

.filter-dropdown-menu {
  padding: 0;
}

.filter-dropdown-menu__item {
  color: $color-primary;
  padding: 5px;

  &.item--active,
  &:hover {
    background-color: $color-secondary;
    color: $color-bright;
  }
}
