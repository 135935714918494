.customer-reviews-container {
  background-color: $color-bright;
}

.review-info {
  margin-bottom: 20px;

  .btn--review-back {
    background-color: $background-color-secondary;
    color: $color-default-lighter;
    font-size: 13px;
    font-weight: $font-weight-normal;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 3px 10px;

    &:hover {
      background-color: $border-color-divider-dark;
    }
  }

  .btn--review-info {
    font-size: 13px;
    font-weight: $font-weight-normal;
    margin-bottom: 5px;
    padding: 3px 10px;
  }
}

.review-info__image{

}

.review-info__label {
  font-size: 18px;
}

.review-info__title {
  font-size: 24px;
}

.review-info-buttons {
  @media only screen and (min-width: $screen-sm-min) {
    text-align: right;
  }
}
