.product-container {
  background-color: $color-bright;
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-price {
  margin: 15px 0 20px;
}

.product-price__price {
  color: $color-primary;
  font-size: 16px;
  margin-right: 10px;
}

.product-price__stock {
  color: $color-success;
  font-size: 16px;

  &.state--out-of-stock {
    color: $color-danger;
  }
}


.product__short-desc {
  color: $color-primary;
  font-weight: $font-weight-light;
  line-height: 22px;
}
