.footer {
  background-color: $background-color-footer-bottom;
  padding-top: $global-padding;
  padding-bottom: 10px;
}

.footer-item {
    margin-bottom: 10px;

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }

    @media only screen and (min-width: $screen-lg-min) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
}

.footer-item__title {
  color: $color-default;
  font-size: 14px;
  font-weight: $font-weight-bold;
}

.footer-item__link {
  color: $color-primary;
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}

.footer-bottom {
  background-color: $background-color-footer;
  color: $color-bright;
  font-size: 11px;
  font-weight: $font-weight-light;
  padding-top: 10px;
  padding-bottom: 10px;
}
