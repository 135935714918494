.product-compare {
  box-shadow: 0 1px 4px rgba(50, 50, 50, 0.3);
  display: none;
  position: fixed;
  right: 1.5%;
  bottom: 0;
  max-width: 300px;
  width: 100%;
  z-index: 100;

  &.is--visible {
    display: block;
  }

  &.is--active {
    .product-compare__title {
      background-color: $color-primary-light;
    }

    .product-compare-content {
      max-height: none;
    }
  }
}

.product-compare__title {
  background-color: $color-secondary;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  color: $color-bright;
  text-align: center;
  padding: 9px 15px;
}

.product-compare-content {
  background-color: $color-bright;
  max-height: 0;
  transition: all 0.5s;
}

.product-compare-items {
  padding: 15px;
  margin: 0;
  list-style: none;
}

.product-compare-item {
  position: relative;
  margin-bottom: 5px;
}

.product-compare-item__link {

}

.product-compare-item__remove {
  color: $color-primary-light;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 3px;
}

.product-compare__btn {

}

.product-compare__clear {
  display: block;
  padding: 10px 0 15px;
}
