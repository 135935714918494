// dropdown-menu class is generated in node_modules/bootstrap-sass
.dropdown-menu {
  > li {
    > a {
      color: $color-primary;
    }
  }

  > li {
    > a:hover,
    > a:focus {
      background-color: transparent;
      color: $color-default;
    }
  }

  > .disabled {
    > a,
    > a:hover,
    > a:focus {
      background-color: transparent;
      color: $color-primary;
    }
  }
}
