.cart-container {
  background-color: $color-bright;

  .btn-danger {
    margin: 10px 0;
  }
}

.cart {

}

.cart-items-table {
  border-bottom: 1px solid $table-border-color;

 th {
   color: $color-primary-light;
   font-weight: $font-weight-light;
 }
}

.cart-item {

}

.cart-item__name {
  font-weight: $font-weight-bold;
}

.cart-item__variant-code {
  font-size: 10px;
  margin: 5px 0;
}

.cart-item-options {

}

.cart-item-options__option {
  border-left: 1px solid $color-primary-light;
  font-size: 12px;
  padding: 0 5px;

  &:first-child {
    border-left-color: transparent;
    padding-left: 0;
  }
}

.cart-item__variant-name {
  font-size: 12px;
}

.cart-item-quantity {
  max-width: 100px;
  min-width: 60px;
}

.cart-item-price {
  color: $color-secondary;
}

.cart-item-price__regular {
  text-decoration: line-through;
}

.cart-item-price__current {

}

.cart-summary {
  background-color: $background-color-secondary;
  padding-top: 15px;
  padding-bottom: 15px;

  .table-responsive {
    border: none;
  }
}

.cart-summary-table {

}

.cart-summary-total {
  border-top: 2px solid $color-default;
  font-size: 18px;
  font-weight: $font-weight-bold;
}

.cart-summary-total__label {

}

.cart-summary-total__value {
  color: $color-secondary;
}

.cart-coupon {
  margin-bottom: 10px;

  .sylius-validation-error {
    margin-left: 15px;
    text-align: left;
  }
}
