.wishlist-container {
  background-color: $color-bright;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wishlist-clear-btn {
  margin-bottom: 10px;
}

.wishlist-item {

}

.wishlist-item__image {

}

.wishlist-item__name {
  font-weight: $font-weight-bold;
}

.wishlist-item__variant-code {
  font-size: 10px;
  margin: 5px 0;
}

.wishlist-item-options {

}

.wishlist-item-options__option {
  border-left: 1px solid $color-primary-light;
  font-size: 12px;
  padding: 0 5px;

  &:first-child {
    border-left-color: transparent;
    padding-left: 0;
  }
}

.wishlist-item__variant-name {
  font-size: 12px;
}

.wishlist-item__price {
  color: $color-primary;
}

.wishlist-item__out_of_stock {

}

.wishlist-item__add-to-cart {

}
