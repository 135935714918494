.attribute-filter{
  list-style: none;
  padding: 0;
  margin: 0;
}

.attribute-filter-label {
  color: $color-default-lighter;
  display: block;
  font-weight: $font-weight-normal;
}

.filter-checkbox {
  cursor: pointer;
  user-select: none;

  .icon--check {
    display: none;
  }
}

.filter-checkbox__icon {
  color: $color-primary-light;
}

.attribute-filter-item__input {
  display: none;

  &:checked {
    + .filter-checkbox {
        .icon--unchecked {
          display: none;
        }
        .icon--check {
          display: inline-block;
        }
    }
  }
}
