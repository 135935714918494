// btn class is generated in node_modules/bootstrap-sass
.btn {
  &:active,
  &.active {
    box-shadow: none;
  }
}

.btn-default {
  color: $color-bright;
  background-color: $background-color-button;
  border-color: transparent;
  font-size: 16px;
  font-weight: $font-weight-bold;
  text-decoration: none;
  transition: background-color $transition-time-300 ease-out;

  &:focus,
  &.focus {
    color: $color-bright;
    background-color: $background-color-button-hover;
    border-color: transparent;
  }

  &:hover {
    color: $color-bright;
    background-color: $background-color-button-hover;
    border-color: transparent;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color-bright;
    background-color: $background-color-button-hover;
    border-color: transparent;

    &:hover,
    &:focus,
    &.focus {
      color: $color-bright;
      background-color: $background-color-button-hover;
      border-color: transparent;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background-color-button-hover;
      border-color: transparent;
    }
  }
}
