// pagination class is generated in node_modules/bootstrap-sass
.pagination-container {
  background-color: $color-bright;
  margin-top: 10px;
}

.pagination {
  font-size: 16px;
  margin: 30px 0;

  > li {
    display: inline-block;
    vertical-align: middle;

    &.pagination--prev,
    &.pagination--next {
      span,
      a {
        background-color: $color-secondary;
        color: $color-bright;
      }

      span:hover,
      a:hover {
        background-color: $color-secondary-alt;
        color: $color-bright;
      }
    }

    &.pagination--prev {
      a,
      span {
        margin-left: 3px;
      }
    }
  }

  > .disabled > span {
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }
  }

  > .disabled > span,
  > .active > span,
  > .active > span:hover,
  > .active > span:focus,
  > li > a,
  > li > a:hover {
    margin: 0 3px;
    padding: 3px 8px;
  }

  > li > a,
  > li > a:focus,
  > li > a:hover {
    background-color: transparent;
    border: none;
    color: $color-secondary;
  }

  > .active {
    > span,
    > span:hover,
    > span:focus {
      background-color: transparent;
      border: none;
      color: $color-default-lighter;
    }
  }
}

.pagination--history {
  margin: 0;
}

.pagination--prev,
.pagination--next {
  &.disabled {
    cursor: not-allowed;
    display: none;
  }
}
