.mini-account {
  color: $color-default;

  &:hover {
    .caret {
      color: $color-secondary;
    }
  }
}

.mini-account__greet {
  color: $color-primary-light;
  position: absolute;
  top: 0;
}

.mini-account-info {
  border: 1px solid $border-color-divider-dark;
  padding: 10px 0;
  margin: 0;
}

.mini-account-info-item__link {
  color: $color-primary;
  position: relative;

  &:hover,
  &:focus {
    &:before{
      content: "";
      position: absolute;
      display: inline;
      width: 3px;
      margin-top: 0;
      vertical-align: middle;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: $background-color-button;
    }
  }
}

.mini-login {
  background-color: $background-color-secondary;
  font-size: 13px;
  font-weight: $font-weight-light;
  padding: 15px 15px 10px;
  margin-bottom: 10px;
  min-width: 200px;

  p {
    margin-top: 5px;
  }
}

.mini-login__register {
  color: $color-secondary;
  font-size: $font-weight-normal;

  &:hover,
  &:focus {
    color: $color-secondary;
    text-decoration: none;
  }
}
