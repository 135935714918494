.product-image-link {

}

.product-image-link__img {
  width: 100%;
}

.product-thumbs-wrapper {
  margin-top: 20px;
}

.product-thumb {
  display: inline-block;
}

.product-thumb-link {

}

.product-thumb-link__img {
  border: 1px solid $color-secondary;
  width: 70px;
}
