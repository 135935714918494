.breadcrumb-container--product,
.breadcrumb-container--wishlist {
  background-color: $color-bright;
}

.breadcrumb {
  font-size: 12px;
  color: $color-primary-light;
  margin-bottom: 0;
  background-color: transparent;
}

.breadcrumb__item,
.breadcrumb__divider {
  display: inline-block;
}

.breadcrumb__item {
  color: $color-default-lighter;

  &.is-active {
    color: $color-primary-light;
  }
}

.breadcrumb__divider {
  font-size: 10px;
}