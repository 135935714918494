.user-container {
  height: 100vh; //fix for full height window
  margin: 0 auto;
  max-width: 400px;
}

.user-container---register {
  max-width: 600px;

  .user {
    text-align: left;
  }

  .sylius-validation-error {
    text-align: left;
  }
}

.user-container__info {
  color: $color-primary;
  font-size: 18px;
  font-weight: $font-weight-light;
  margin-bottom: 20px;
  text-align: center;
}

.user {
  text-align: center;

  &.user--my-account {
    text-align: left;

    .sylius-validation-error {
      text-align: left;
    }
  }

  .hide-form-errors {
    .sylius-validation-error {
      display: none;
    }
  }
}

.user-form {
  background-color: $color-bright;
  padding: 30px 50px 20px;

  p {
    font-weight: $font-weight-bold;
  }
}

.user-form-error {
  color: $color-danger;
  font-size: 10px;

  .header {
    padding: 0;
    border: none;
  }
}

.user-buttons-wrapper {
  background-color: $background-color-secondary;
  padding: 18px;

  &.wrapper--register {
    text-align: center;
  }
}

.user__submit-btn {
  display: block;
  margin: 0 auto 10px;
  min-width: 50%;
}
