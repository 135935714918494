.horizontal-products-wrapper {
  padding-top: $global-padding;

  .horizontal-products {
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }

    @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }

    @media only screen and (min-width: $screen-lg-min) {
      &:nth-child(4n+1) {
        clear: both;
      }
    }
  }
}

.horizontal-product-image {
  display: block;
  height: 200px;
  padding: 8px;
  text-align: center;

  img {
    max-height: 150px;
  }
}

.horizontal-product {
  background-color: $background-color-primary;
  border-radius: 2px;
  box-shadow: 1px 3px 1px rgba(50, 50, 50, 0.1);
  margin-bottom: 20px;

  &:hover {
    box-shadow: 0 3px 3px rgba(50, 50, 50, 0.2);
  }
}

.horizontal-product-info {
  padding: 10px 15px;
}

.horizontal-product-info__name {
  color: $color-default;
  display: block;
  font-weight: $font-weight-bold;
  height: 40px;
  margin-bottom: 5px;
  overflow: hidden;
}

.horizontal-product-info__price {
  color: $color-primary;
}

.product-actions {
  min-height: 35px;
  padding: 0 15px 10px;
  position: relative;
}

.product-mini-cart__add {
  span {
    font-size: 20px;
    vertical-align: bottom;
  }
}

.product-actions-list {
  color: $color-primary-light;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 15px;
}
