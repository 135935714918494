.compare-container {
  background-color: $color-bright;
}

.compare-container__no-results {
  margin-top: 10px;
}

.product-compare-clear {
  margin: 10px 0;
}

.compare-table {
  color: $color-primary;

  tr:first-child {
    td {
      border-top: none;
    }
  }
}

.compare-table__img {
  height: 220px;
  text-align: center;
}

.compare-table__title {
  font-weight: $font-weight-bold;
}

.compare-table__price {
  margin: 20px 0;
}

.compare-table__remove-item {
  display: block;
  margin-top: 10px;
}

.compare-table__key {
  font-weight: $font-weight-bold;
}

.compare-table__value {
  font-weight: $font-weight-light;
}
