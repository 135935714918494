.form-row {
  margin-bottom: 10px;

  &.row--checkbox,
  &.row--radio {
    .form__label {
      cursor: pointer;
      margin-left: 20px;
    }
  }

  &.row--select {
    .form-input__input {
      background-color: $color-bright;
    }
  }
}

.form__label {
  color: $color-secondary;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 0;
}

.form-input {
  position: relative;
}

.form-input__input {
  border: none;
  box-shadow: none;
  color: $color-primary;
  padding: 0 8px 5px;
}

.form__checkbox,
.form__radio {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 15px;
  padding: 0;
}
