// lightbox class is generated in node_modules/lightbox2

/* Preload images */
body {
  &:after {
    content: url(../img/close.png) url(../img/loading.gif) url(../img/prev.png) url(../img/next.png);
    display: none;
  }
  &.lb-disable-scrolling {
    overflow: hidden;
  }
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;

  .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;

    /* Image border */
    border: 4px solid white;
  }

  a {
    img {
      border: none;
    }
  }
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  // Background color behind image. This is visible during transitions
  background-color: white;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;

  a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');

    &.lb-prev {
      width: 34%;
      left: 0;
      float: left;
      background: url(../img/prev.png) left 48% no-repeat;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      transition: opacity 0.6s;

      &:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
      }
    }

    &.lb-next {
      width: 64%;
      right: 0;
      float: right;
      background: url(../img/next.png) right 48% no-repeat;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      transition: opacity 0.6s;

      &:hover {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
        opacity: 1;
      }
    }

  }
}

.lb-container > .nav {
  left: 0;
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.lb-data {
  padding: 0 4px;
  color: #ccc;

  .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }

  .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
  }

  .lb-caption a {
    color: #4ae;
  }

  .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
  }

  .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(../img/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
    }
  }
}
