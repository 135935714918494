// dropdown-menu class is generated in node_modules/bootstrap-sass
.nav {
  > li {
    > a {
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
