.btn-danger {
  font-size: 16px;
  font-weight: $font-weight-bold;
}

.btn--filter {
  padding: 2px 12px;
}

.btn--small {
  padding: 3px 10px;
  font-size: 14px;
}

.btn-alt {
  background-color: transparent;
  border: none;
  color: $color-primary-light;
  font-size: 12px;
  font-weight: $font-weight-bold;
  padding: 0;

  &:focus,
  &:hover {
    color: $color-primary;
  }
}
