.offer-container {
  height: 100vh; //fix for full height window
  margin: 0 auto;
  max-width: 400px;
}

.offer {
  margin-top: 50px;
  text-align: center;

  @media only screen and (min-width: $screen-sm-min) {
    margin-top: 100px;
  }
}

.offer-info {
  background-color: $color-bright;
  padding: 20px 30px 20px;
}

.offer-buttons-wrapper {
  background-color: $background-color-secondary;
  padding: 18px;
}

.offer__submit-btn {
  display: block;
  margin: 0 auto 10px;
  min-width: 50%;
}
