@mixin off-canvas-container {
  background-color: $background-color-sidebar;
  color: $color-bright;
  width: $offcanvas-width;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 30;
}

.side-nav-container {
  @include off-canvas-container();

  right: 0;
  overflow-x: hidden;
  transform: translate3d(100%, 0, 0);
}

.filters-container {
  @media only screen and (max-width: $screen-xs-max) {
    @include off-canvas-container();

    left: 0;
    margin: 0;
    padding: 0;
    transform: translate3d(-100%, 0px, 0px);
  }
}

// Filters colors when in sidebar.
.filters {
  @media only screen and (max-width: $screen-xs-max) {
    background-color: $background-color-sidebar;

    .heading,
    .filter-dropdown,
    .price-filter-elements,
    .attribute-filter-label,
    .filter-checkbox__icon {
      color: $color-bright;
    }

    .half-border,
    .noUi-target,
    .noUi-connect,
    .noUi-handle {
      background: $color-bright;
    }
  }
}

.main-container {
  // offcanvas (sidebar) transitions in XS
  @media only screen and (max-width: $screen-xs-max) {
    transition: transform .1s;
  }

  &.move-left,
  &.move-right {
    overflow: visible;

    // layer on content when sidebar is active.
    &:after {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background $transition-time-500 ease;
      z-index: 20;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }

  &.move-left {
    transform: translate3d($offcanvas-width-negative, 0, 0);
  }

  &.move-right {
    transform: translate3d($offcanvas-width, 0, 0);
  }
}

.filters-bar-opener {
  cursor: pointer;
  margin-top: 15px;
}
