.product-cart {
  background-color: $background-color-secondary;
  padding: 15px;
}

.product-cart-row {
  margin-bottom: 15px;
}

.product-cart__label {
  margin-right: 5px;
  vertical-align: top;
}

.product-cart-input {
  position: relative;
}

.product-cart-input__input {
  background-color: $background-color-secondary;
  border: none;
  box-shadow: none;
  color: $color-primary;
  width: auto;
  height: auto;
  padding: 0 8px 5px;
  text-align: center;

  &:focus {
    box-shadow: none;
  }
}

.input--quantity {
  width: 50px;
}

.product-cart__add {
  position: relative;
  margin: 0 0 15px;

  span {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}

.product-cart--wishlist {
  background-color: transparent;
  padding: 5px 0;

  .product-cart-row {
    margin-bottom: 5px;
  }

  .product-cart-input__input {
    background-color: transparent;
  }
}
