.contact-container {
  background-color: $color-bright;
}

.contact {
  p {
    font-weight: $font-weight-bold;
  }

  .sylius-validation-error {
    margin: 20px 0 5px;
    text-align: left;
  }
}

.contact-field-row {
  margin-bottom: 10px;

  &.contact--last-row {
    margin-bottom: 30px;
  }
}

.contact__label {
  color: $color-secondary;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 0;
}

.contact-input {
  position: relative;
  width: 200px;
}

.contact-input__input {
  border: none;
  box-shadow: none;
  color: $color-primary;
  padding: 0 8px 5px;
}

.contact__textarea {
  border: 1px solid $border-color-input;
  display: block;
  padding: 8px 5px;
  width: 80%;
  resize: none;
}
