.m-a-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.m-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.m-t-0 {
  margin-top: 0;
}

.m-r-0 {
  margin-right: 0;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-l-0 {
  margin-left: 0;
}

.p-a-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.p-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-t-0 {
  padding-top: 0;
}

.p-r-0 {
  padding-right: 0;
}

.p-b-0 {
  padding-bottom: 0;
}

.p-l-0 {
  padding-left: 0;
}

.w-100 {
  width: 100%;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.not-clickable {
  pointer-events: none;
}
