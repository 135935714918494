.mini-cart {
  color: $color-primary-light;
  padding: 10px 0;

  &:hover {
    color: $color-secondary;

    .caret {
      color: $color-secondary;
    }
  }
}

.mini-cart__items {
  position: absolute;
  top: 0;
  left: 35px;
}

.mini-cart__title {
  color: $color-default;
}

.mini-cart-info {
  border: 1px solid $border-color-divider-dark;
  padding: 0 0 15px;
  margin: 0;
  list-style: none;
  min-width: 300px;
}

.mini-cart-empty {
  color: $color-primary;
  font-size: 13px;
  font-weight: $font-weight-light;
  padding: 5px 15px 10px;
}

.mini-cart-title {
  border-bottom: 1px solid $border-color-divider;
  font-weight: $font-weight-bold;
  position: relative;
  padding: 10px 15px;

  span {
    color: $color-primary-light;
  }
}

.mini-cart-title__link {
  font-size: 18px;
  position: absolute;
  top: 8px;
  right: 15px;
  padding: 0 !important;

  span {
    color: $color-primary-light;
  }
}

.mini-cart-summary {
  background-color: $background-color-secondary;
  color: $color-primary-light;
  font-size: 12px;
  font-weight: $font-weight-light;
  line-height: 30px;
  padding: 15px 0;

  span {
    color: $color-default;
    font-size: 16px;
    font-weight: $font-weight-bold;
    padding-left: 5px;
  }
}

.mini-product {
  padding: 5px 0 10px;
}

.mini-product__img {
  img {
    max-width: 60px;
    max-height: 60px;
  }
}

.mini-product__title {
  color: $color-default-lighter;
  font-size: 12px;
  font-weight: $font-weight-bold;

  &:hover {
    color: $color-default-lighter;
    text-decoration: none;
  }
}

.mini-product__price {
  color: $color-default-lighter;
  font-size: 12px;
  font-weight: $font-weight-bold;
}

.mini-product__quantity {
  color: $color-primary-light;
  font-size: 11px;
}
