@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSansLight'),
  url('../fonts/OpenSans-Light.woff2') format('woff2'),
  url('../fonts/OpenSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
  url('../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSansSemibold'),
  url('../fonts/OpenSans-Semibold.woff2') format('woff2'),
  url('../fonts/OpenSans-Semibold.woff') format('woff');
}
