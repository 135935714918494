.header {
  background-color: $background-color-primary;

  @media only screen and (max-width: $screen-xs-max) {
    border-bottom: 4px solid $border-color-divider-dark;
  }
}

.mini-menu {
  padding-top: 5px;
}

.mini-menu__user,
.mini-menu__cart,
.mini-menu__wishlist,
.mini-menu__submenu {
  color: $color-primary-light;
  display: inline-block;
  font-size: 18px;
}

.mini-menu__user {
  padding-right: 10px;
}

.mini-menu__cart {
  padding-right: 5px;

  @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    border-left: 1px solid $color-primary-light;
    padding-left: 10px;
    padding-right: 8px;
  }
}

.mini-menu__wishlist {
  border-left: 1px solid $color-primary-light;
  padding-left: 10px;
}

.mini-menu__submenu {
  color: $color-secondary;
  border-left: 1px solid $color-primary-light;
  padding-left: 10px;

  &:hover,
  &:focus {
    color: $color-secondary;
  }
}
