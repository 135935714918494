// nouislider - 10.1.0 - 2017-07-28 17:11:18
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
  outline: none;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

// Slider size and handle placement;
.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  width: 7px;
  height: 16px;
  left: -5px;
  top: -6px;
}

// Styling;
.noUi-target {
  background: $color-primary-light;
}

.noUi-connect {
  background: $color-primary-light;
}

// Handles and cursors;
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-handle {
  border-radius: 2px;
  background: $color-primary;
  cursor: default;
}
