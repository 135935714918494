// table class is generated in node_modules/bootstrap-sass
.order-history {
  > tbody {
    > tr {
      > td {
        vertical-align: middle;
      }
    }
  }
}

.cart-summary-table {
  > tbody {
    > tr {
      > td {
        border-top: none;
      }
    }
  }
}
