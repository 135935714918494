.product-links-wrapper {
  padding: 15px;
}

.product-link {
  color: $color-default-lighter;
  display: block;
  font-size: 14px;
  font-weight: $font-weight-normal;
  padding-bottom: 5px;

  .product-link__icon {
    color: $color-primary-light;
    margin-right: 5px;
  }

  &:hover {
    color: $color-secondary;

    .product-link__icon {
      color: $color-primary;
    }
  }
}

.product-compare-btn,
.product-wishlist-btn {
  cursor: pointer;
}

.product-compare-btn,
.product-wishlist-btn {
  &.btn--active {
    color: $color-secondary;

    .product-compare-btn__trans,
    .product-wishlist-btn__trans {
      display: none;
    }

    .product-compare-btn__trans-active,
    .product-wishlist-btn__trans-active {
      color: $color-secondary;
      display: inline;
    }
  }
}

.product-compare-btn__trans,
.product-wishlist-btn__trans {

}

.product-compare-btn__trans-active,
.product-wishlist-btn__trans-active {
  display: none;
}
