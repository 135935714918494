.customer-review {
  font-size: 12px;
  margin-bottom: 10px;
}

.customer-review__title {
  font-size: 14px;
  font-weight: $font-weight-bold;
}

.customer-review__comment {
  color: $color-primary;
  font-size: 13px;
  padding: 10px 0;
}

.customer-review__date {
  color: $color-primary-light;

  span {
    font-weight: $font-weight-bold;
  }
}
