.product-tabs-container {
  background-color: $color-bright;
}

.product-tab-menu {
  margin-bottom: 0;

  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.product-tab-wrapper {
  border-top: 3px solid $border-color-divider-dark;
}

.product-tab {
  p,
  table {
    color: $color-primary;
  }

  p {
    line-height: 24px;
  }
}
