body {
  background-color: $background-color-body;
  color: $color-default;
  font-family: $font-family-base;
}

a {
  color: $color-default;

  &:hover,
  &:focus {
    color: $color-default;
    text-decoration: none;
  }
}

a,
input,
button,
select,
textarea,
:focus,
.btn {
  &:active:focus,
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}

.main-container {
  background-color: $background-color-main-container;
  box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.4);
  padding-left: 0;
  padding-right: 0;
  position: relative;
  overflow: hidden;

  &.affixed {
    top: 55px;
  }

  .sylius-flash-message{
    padding: 10px;

    &.positive {
      background-color: $color-success;
      color: $color-bright;
    }

    &.negative {
      background-color: $color-danger;
      color: $color-bright;
    }

    // flashes tpl is also used in admin, so below some elements is modified in front part.
    i {
      display: none;
    }

    .content,
    .header {
      background: transparent;
      margin: 0;
      padding: 0;
      border-bottom: none;
    }
  }
}

// used in login/checkout
.custom-container {
  background-color: $background-color-login;
  background: $background-gradient-login;
  box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.4);
  padding-left: 0;
  padding-right: 0;
}

.account-menu-container,
.filters-container {
  @media only screen and (min-width: $screen-sm-min) {
    background-color: white;
    box-shadow: $box-shadow;
  }
}

.account-menu,
.filters {
  background-color: white;

  @media only screen and (max-width: $screen-xs-max) {
    box-shadow: $box-shadow;
  }

  @media only screen and (min-width: $screen-sm-min) {
    padding: $global-padding 0 $global-padding $global-padding;
  }
}

.divider {
  border: 0;
  border-top: 1px solid $border-color-divider;
  margin: 10px 0;

  &.color--dark {
    border-color: $border-color-divider-dark;
  }
}

.vertical-divider {
  border-left: 1px solid $color-primary;
}

.half-border {
  background-color: $border-color-input;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  z-index: 5;

  &:before,
  &:after {
    content: " ";
    background-color: $border-color-input;
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 5px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

input {
  &:focus {
    + .half-border {
      background-color: $color-secondary;

      &:before,
      &:after {
        background-color: $color-secondary;
      }
    }
  }
}

textarea {
  border: 1px solid $border-color-input;
  resize: none;

  &:hover,
  &:focus {
    border-color: $color-secondary;
  }
}

.sylius-validation-error {
  color: $color-danger;
  display: block;
  margin: 5px 0;
  padding: 0;
}

label {
  &.required {
    &:after {
      content: '*';
      color: $color-primary;
    }
  }
}

.link {
  color: $color-secondary;
  margin: 5px 0;

  &:focus,
  &:hover {
    color: $color-secondary-alt;
  }
}

.link--button {
  background-color: transparent;
  border: none;
}

.link-alt {
  color: $color-primary;
  font-size: 13px;

  &:focus,
  &:hover {
    color: $color-secondary;
  }
}

.navbar-brand {
  padding: 10px 15px;
}
