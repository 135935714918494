.main-menu {
  background-color: $background-color-primary;
  border-bottom: 4px solid $border-color-divider-dark;
  margin-bottom: 0;

  &.affix {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    .main-menu-header {
      display: block;
    }

    .mini-account__greet,
    .mini-cart__items {
      top: 5px;
    }
  }

  // bootstrap affix show for LG only
  @media only screen and (max-width: $screen-md-max) {
    position: relative;
  }

  .caret {
    color: $color-primary-light;
  }
}

.main-menu-header {
  display: none;
}

.main-menu-item__link,
.main-menu-item__link:hover,
.main-menu-item__link:focus {
  color: $color-default;

  &.is--active-menu{
    color: $color-secondary;
  }
}

// submenu items style
.main-menu-dropdown,
.main-menu-dropdown.is--active-menu {
  .main-menu-item__link:hover,
  .main-menu-item__link:focus,
  .is--active-submenu {
    position: relative;

    &:before{
      content: "";
      position: absolute;
      display: inline;
      width: 3px;
      margin-top: 0;
      vertical-align: middle;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: $background-color-button;
    }
  }

  .is--active-submenu {
    color: $color-default;
  }
}
