.side-nav {
  position: relative;
  left: 0;
  transition: $transition-time-500;
}

.side-menu,
.side-menu--submenu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.side-menu-label {
  border-bottom: 2px solid $border-color-sidebar;
  color: $color-primary;
  font-size: 13px;
  font-weight: $font-weight-bold;
  padding: 8px 0 8px 15px;
  text-transform: uppercase;
}

.side-menu-item__link {
  display: block;
  border-bottom: 1px solid $border-color-sidebar;
  color: $color-bright;
  padding: 8px 15px;
  font-size: 16px;
  text-decoration: none;
  transition: $transition-time-500;

  &:hover {
    background-color: $background-color-sidebar-lighter;
    color: $color-bright;
    text-decoration: none;
  }

  span {
    float: right;
    font-size: 14px;
    top: 4px;
  }
}

.side-menu-item__link--back {
  font-size: 14px;

  span {
    float: none;
    top: auto;
    color: $color-primary-light;
    font-size: 10px;
    font-weight: bold;
  }
}

.side-menu--submenu {
  display: none;
  position: absolute;
  top: 0;
  right: $offcanvas-width-negative;
  width: 100%;
  height: 100%;
  transition: right $transition-time-500;
}

.side-menu--submenu {
  &.in {
    display: block;
  }
}
