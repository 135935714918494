.mini-review {
  font-size: 12px;

  @media only screen and (max-width: $screen-xs-max) {
    margin-bottom: 10px;
  }
}

.mini-review__list,
.mini-review__add {
  color: $color-primary-light;
  padding: 0 5px;

  &:hover {
    color: $color-secondary;
    text-decoration: underline;
  }
}

.tab--review {
  .mini-review__list,
  .mini-review__add {
    color: $color-secondary;
    font-size: 14px;
    padding: 0;

    &:hover {
      color: $color-secondary-alt;
    }
  }

  .mini-review__list {
    padding-right: 10px;
  }
}

.mini-review-stars {
  display: inline-flex;
  white-space: nowrap;
  vertical-align: baseline;
  pointer-events: none;
}
