.order {

}

.order__info {
  margin-bottom: 20px;

  .vertical-divider {
    margin: 0 5px;
  }
}

.order-payment,
.order-shipment {
  margin-bottom: 20px;

  span {
    font-weight: $font-weight-bold;
  }
}

.order-payment {

}

.order-shipment {

}

.order-address {
  margin-bottom: 20px;
}

.order-address__title {
  border-bottom: 1px solid $border-color-divider-dark;
  font-weight: $font-weight-bold;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.order__subtotal,
.order__tax-total,
.order__total,
.order__total-base-currency,
.order__promotion-total,
.order__shipping-total {
  @media only screen and (min-width: $screen-sm-min) {
    text-align: right;
  }
}

