$font-family-base: 'Open Sans', sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$offcanvas-width: 250px;
$offcanvas-width-negative: -250px;

$transition-time-500: 0.5s;
$transition-time-300: 0.3s;

$global-padding: 20px;

$color-bright: #ffffff;
$color-default: #000000;
$color-default-lighter: #222222;
$color-primary: #777777;
$color-primary-light: #999999;
$color-secondary: #27ccc0;
$color-secondary-alt: #1ba39c;

$color-danger: #ea1818;
$color-success: #A1BA16;

$border-color-divider: #f4f4f4;
$border-color-divider-dark: #e5e5e5;
$border-color-sidebar: #444444;
$border-color-input: $color-primary-light;

$background-color-primary: $color-bright;
$background-color-secondary: $border-color-divider;
$background-color-body: #f0f0f0;
$background-color-main-container: $border-color-divider;
$background-color-footer: #333333;
$background-color-footer-bottom: $border-color-divider-dark;
$background-color-sidebar: #333333;
$background-color-sidebar-lighter: #272727;
$background-color-button: $color-secondary;
$background-color-button-hover: $color-secondary-alt;

$background-color-login: #ccd6d5;
$background-gradient-login: linear-gradient(135deg, #b4bdbf 0%,#d7dddc 33%,#ccd6d5 67%,#b7c9c7 100%);

$box-shadow: 1px 3px 1px rgba(50, 50, 50, 0.1);

$table-border-color: #dddddd;
