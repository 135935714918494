.search-input {
  display: table-cell;
  position: relative;
}

.search-input__input {
  background-color: inherit;
  box-shadow: none;
  border: none;
  color: inherit;
  padding: 8px;
}

.search__icon {
  background-color: inherit;
  border: inherit;
  color: $color-primary-light;
  font-size: 18px;
}

.search--sidebar {
  padding: 10px;
}

.search-results {
  display: none;

  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  background-color: $color-bright;
  border: 1px solid $color-primary-light;
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 1;
}

.search-results__item {
  display: block;
  font-size: 12px;
  padding: 4px 6px;

  &:hover {
    background-color: $background-color-body;
  }

  &.item--all-results {

  }

  &.item--no-results {

  }
}
