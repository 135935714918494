.checkout-container {
  height: 100vh; //fix for full height window
  margin: 0 auto;
  max-width: 600px;

  p {
    font-weight: $font-weight-bold;
  }

  .form {
    background-color: $color-bright;
  }

  .sylius-validation-error {
    text-align: left;
  }
}

.checkout-address-shipping,
.checkout-address-billing,
.checkout-shipment,
.checkout-payment,
.checkout-summary {
  padding: 15px;
}

.checkout-address-billing {
  padding-top: 0;
}

.checkout-address-shipping-different-addr {
  padding-top: 15px;
}

.checkout-buttons {
  background-color: $background-color-secondary;
  padding: 15px;

  .btn-default {
    min-width: 30%;
  }

  .link-alt {
    margin-top: 10px;
  }
}

.checkout-buttons-alt {
  margin-top: 10px;
}

.checkout-shipment-item,
.checkout-payment-item {
  margin-bottom: 10px;
}

.checkout-shipment-item__label,
.checkout-payment-item__label {
  cursor: pointer;
}

.checkout-shipment-item__desc,
.checkout-payment-item__desc {
  font-size: 12px
}

.checkout-login {
  .btn-default {
    margin: 5px 0 20px;
  }
}

.address-book-select {

}

.address-book-select-item {
  position: relative;

  .form__label {
    display: block;
  }

  .form__radio {
    left: 0;
  }
}

.address-book-select-item__edit {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
