.products-container {
  .category-products {
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }

    @media only screen and (min-width: $screen-lg-min) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }
}

.heading-items-total {
  color: $color-primary;
  font-size: 14px;
  font-weight: $font-weight-light;
  margin: 0 0 15px;
  text-align: left;
  text-transform: capitalize;

  @media only screen and (min-width: $screen-sm-min) {
    margin-top: 20px;
    text-align: right;
  }
}
