.category-filter-link {
  color: $color-default-lighter;
  display: block;
  margin-bottom: 5px;

  &:active,
  &:focus,
  &:visited {
    .icon--unchecked {
      display: none;
    }

    .icon--check {
      display: inline-block;
    }
  }
}

.category-filter-link__icon {
  color: $color-primary-light;

  &.icon--unchecked {

  }

  &.icon--check {
    display: none;
  }
}
