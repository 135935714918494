// form class is generated in Sylius node_modules/semantic-ui-css

/*--------------
    Loading
---------------*/

form {
  &.loading {
    position: relative;
    cursor: default;
    pointer-events: none;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background: rgba(244, 244, 244, 0.8);
      width: 100%;
      height: 100%;
      z-index: 100;
    }

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -1.5em 0em 0em -1.5em;
      width: 3em;
      height: 3em;
      animation: form-spin 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: #767676 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
      border-style: solid;
      border-width: 0.2em;
      box-shadow: 0 0 0 1 transparent;
      visibility: visible;
      z-index: 101;
    }
  }
}

@-webkit-keyframes form-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes form-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-control {
  &:focus {
    box-shadow: none;
  }
}
