.checkout-steps {
  margin-top: 10px;
}

.checkout-step {
  position: relative;
  padding: 0 15px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 45%;
    display: block;
  }

  &:before {

    width: 6px;
    height: 6px;
    border-radius: 1000px;
    background: $border-color-divider-dark;
    top: -13px;
  }

  &:after {
    width: 100%;
    height: 2px;
    margin-left: 6px;
    background: $border-color-divider;
    top: -11px;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
}

.checkout-step__link {
  &.active {
    color: $color-secondary;
  }

  &.disabled {
    cursor: auto;
    pointer-events: none;
  }
}
