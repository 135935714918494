.account-menu-container {
  margin-bottom: 20px;
}

.account-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 15px;
}

.account-menu-item {

}

.account-menu-item__link {
  color: $color-primary;
  display: block;
  padding: 8px 0;

  &.is--active {
    color: $color-default;
  }

  &:hover,
  &:focus {
    color: $color-default;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      display: inline;
      width: 3px;
      margin-top: 0;
      vertical-align: middle;
      top: 0;
      left: -10px;
      bottom: 0;
      background-color: $background-color-button;
    }
  }
}
