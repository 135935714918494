.account {
  background-color: $color-bright;
  box-shadow: $box-shadow;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 20px;

  .btn-default {
    font-size: 14px;
    margin-bottom: 5px;
    padding: 6px 8px;
  }
}

.account__greeting {
  font-size: 15px;
  font-weight: $font-weight-bold;
}

.account__info {
  font-size: 15px;
  font-weight: $font-weight-light;
  color: $color-primary;
}

.account__paragraph-title {
  font-size: 15px;
  font-weight: $font-weight-bold;
}

.account-email-status {
  background-color: $color-bright;
  border: 1px solid transparent;
  border-radius: 3px;
  color: $color-bright;
  font-size: 8px;
  font-weight: $font-weight-bold;
  line-height: 20px;
  margin-left: 10px;
  padding: 4px 6px;
  vertical-align: middle;

  &.status--verified {
    color: $color-success;
    border-color: $color-success;
  }

  &.status--not-verified {
    color: $color-danger;
    border-color: $color-danger;
  }
}

.verification-form {
  margin-top: 10px;
}

.order-history {
  color: $color-primary;
}

.order-history th,
.order-history__column-link {
  color: $color-default-lighter;
}

.order-history__state {

  &.state--cancel {

  }

  &.state--fulfill {

  }
}

.account-address {
  margin-bottom: 20px;

  address {
    line-height: 22px;
    margin-bottom: 10px;
  }
}

.account-address-edit {
  margin-bottom: 5px;
}
