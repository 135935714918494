.container {
  @media (min-width: $screen-sm-min) {
    width: 100%;
  }
  @media (min-width: $screen-md-min) {
    width: 100%;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.carousel-control {
 &.left,
 &.right {
  background: none;
 }
}
