.header,
.container-fluid,
.breadcrumb-container,
.main-slider-content,
.general-heading,
.horizontal-products-wrapper,
.homepage-banners,
.footer,
.footer-bottom,
.product-container,
.product-tab,
.customer-reviews-container,
.contact-container,
.cart,
.content,
.compare-container,
.wishlist-container {
  padding-left: 0;
  padding-right: 0;

  @media only screen and (min-width: $screen-sm-min) {
    padding-left: $global-padding;
    padding-right: $global-padding;
  }
}

.header,
.main-slider,
.homepage-banners,
.product-tab,
.customer-reviews-container,
.contact-container,
.cart,
.content {
  padding-top: $global-padding;
  padding-bottom: $global-padding;
}

.main-menu .navbar-right,
.pagination.pull-right  {
  padding-right: 0;

  @media only screen and (min-width: $screen-sm-min) {
    padding-right: 15px;
  }
}
