.heading {
  color: $color-default-lighter;
  margin: 15px 0;

  &:first-child,
  &:last-child {
    margin: 15px 0;
  }
}

.heading--category {
  font-size: 24px;
}

.heading--filter {
  font-size: 16px;
  font-weight: $font-weight-light;
}

.heading--price {

}

.heading--product {
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 5px;
}

.heading--review-title {
  border-bottom: 1px solid $color-primary-light;
  font-size: 20px;
  padding-bottom: 8px;

  &:first-child {
    margin-top: 8px;
  }
}

.heading--review-product {
  font-size: 24px;
  font-weight: 300;
  margin: 20px 0;
}

.heading--tab {

}

.heading--contacts {
  font-size: 40px;
  font-weight: $font-weight-light;
  margin-bottom: 40px;
}

.heading--login,
.heading--checkout {
  color: $color-bright;
  font-size: 38px;
  font-weight: $font-weight-light;
  margin-bottom: 10px;
  text-align: center;
}

.heading--account {
  border-bottom: 2px solid $border-color-divider-dark;
  font-size: 28px;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.heading--wishlist {
  &:first-child {
    margin-top: 0;
  }
}
