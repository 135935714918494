.new-review {
  background-color: $background-color-secondary;
  padding: 15px;

  p {
    margin: 0 0 10px;
    font-weight: 300;
    color: $color-primary;
    font-style: italic;
  }

  .sylius-validation-error {
    margin: 20px 0 5px;
    text-align: left;
  }
}

.new-review-field-row {
  margin-bottom: 10px;
}

.new-review__label {
  color: $color-secondary;
  font-size: 13px;
  font-style: italic;
  margin-bottom: 0;
}

.new-review-input {
  position: relative;
  width: 200px;
}

.new-review-input__input {
  background-color: $background-color-secondary;
  border: none;
  box-shadow: none;
  color: $color-primary;
  padding: 0 8px 5px;
}

.new_review__textarea {
  background-color: $background-color-secondary;
  display: block;
  padding: 8px 5px;
  width: 80%;
}
